
#intro h1 {
  text-indent: -9999em; }
#intro {
  position: relative;
  color: white;
  width: 100%;
  height: 350px;
  overflow: hidden; }
@media (min-width: 1200px) {
    #intro {
      height: 100vh; }
      body.thanks #intro {
        height: 85vh; } }
#intro::before {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    content: " ";
    width: 400%;
    height: 400%;
    background-image: url(/img/perpetuity.png);
    background-position: center center;
    background-repeat: repeat;
    overflow: visible;
    opacity: 1;
    background-size: 33% auto;
    -webkit-transform: translateX(-50%) translateY(-25%);
            transform: translateX(-50%) translateY(-25%);
    -webkit-animation: perpetuity 15s linear infinite;
            animation: perpetuity 15s linear infinite;
    -webkit-animation-delay: 3.5s;
            animation-delay: 3.5s; }
@media (min-width: 768px) {
      #intro::before {
        background-size: auto;
        width: 600%;
        height: 600%;
        -webkit-animation: perpetuity 25s linear infinite;
                animation: perpetuity 25s linear infinite;
        -webkit-animation-delay: 3.5s;
                animation-delay: 3.5s; } }
@media (min-width: 1200px) {
      #intro::before {
        width: 800%;
        height: 800%;
        -webkit-animation: perpetuity 35s linear infinite;
                animation: perpetuity 35s linear infinite;
        -webkit-animation-delay: 3.5s;
                animation-delay: 3.5s; }
        body.thanks #intro::before {
          -webkit-animation: none;
                  animation: none;
          filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="10" /></filter></svg>#filter');
          -webkit-filter: blur(10px);
                  filter: blur(10px);
          width: 100%;
          height: 100%;
          background-position: 10% center; } }
#intro::after {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(/img/wes-deer-inverse.svg);
    background-position: center center;
    background-size: auto 90%;
    content: ' ';
    opacity: 1; }
@media (min-width: 768px) {
      #intro::after {
        left: 0;
        background-size: auto 100%;
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur in="SourceAlpha" stdDeviation="1" /><feOffset dx="3" dy="3" result="offsetblur" /><feFlood flood-color="rgba(35,166,213,0.5)" /><feComposite in2="offsetblur" operator="in" /><feMerge><feMergeNode /><feMergeNode in="SourceGraphic" /></feMerge></filter></svg>#filter');
        -webkit-filter: drop-shadow(2px 2px 1px rgba(35, 166, 213, .5));
                filter: drop-shadow(2px 2px 1px rgba(35, 166, 213, .5)); } }
@media (min-width: 1600px) {
      #intro::after {
        left: 0;
        background-size: 130em auto;
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur in="SourceAlpha" stdDeviation="1" /><feOffset dx="5" dy="5" result="offsetblur" /><feFlood flood-color="rgba(35,166,213,0.5)" /><feComposite in2="offsetblur" operator="in" /><feMerge><feMergeNode /><feMergeNode in="SourceGraphic" /></feMerge></filter></svg>#filter');
        -webkit-filter: drop-shadow(4px 4px 1px rgba(35, 166, 213, .5));
                filter: drop-shadow(4px 4px 1px rgba(35, 166, 213, .5)); } }
body.thanks #intro::after {
      background-image: url(/img/wes-deer-inverse-thanks.svg); }
#intro .wrap {
    padding: 0 !important;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 2; }
#intro .wrap::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 300%;
      height: 100%;
      content: " ";
      background: -webkit-gradient(linear, left bottom, right top, from(#EE7752), color-stop(#E73C7E), color-stop(#23A6D5), to(#23D5AB));
      background: linear-gradient(to top right, #EE7752, #E73C7E, #23A6D5, #23D5AB);
      -webkit-animation: gradient-move 3s linear infinite;
              animation: gradient-move 3s linear infinite;
      opacity: .7; }
#intro .opening {
  position: absolute;
  top: 34%;
  left: 50%;
  width: 245px;
  margin-left: -125px;
  font-weight: lighter;
  color: rgba(255, 255, 255, .8);
  text-align: center;
  opacity: .75;
  -webkit-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
  position: absolute;
  z-index: 8;
  margin-top: 100px; }
#intro .opening h3 {
    font-size: 1.28em; }
@media (min-width: 768px) {
    #intro .opening {
      margin-top: 115px;
      width: 300px;
      margin-left: -150px; }
      #intro .opening h3 {
        font-size: 1.5em; } }
@media (min-width: 1200px) {
    #intro .opening {
      margin-top: 320px;
      width: 870px;
      margin-left: -435px; }
      #intro .opening h3 {
        font-size: 4.5em; } }
@media (min-width: 1600px) {
    #intro .opening {
      margin-top: 300px;
      width: 700px;
      margin-left: -350px; }
      #intro .opening h3 {
        font-size: 3.5em; } }
#intro .opening:hover {
    opacity: 1; }
#intro .opening h3 {
    margin: 0;
    padding: 0;
    line-height: 100%;
    text-align: justify;
    -moz-text-align-last: justify;
         text-align-last: justify;
    white-space: nowrap;
    text-transform: uppercase;
    position: relative; }
body.thanks #intro .opening h3 {
      text-align: center;
      -moz-text-align-last: center;
           text-align-last: center;
      font-size: 6em; }
body.thanks #intro .opening h3::after {
        display: none; }
#intro .opening h3 span {
      font-family: Georgia, Cursive;
      font-size: 1.2em;
      color: rgba(0, 0, 0, .33); }
#intro .opening h3::after {
      position: absolute;
      left: 50%;
      content: '';
      display: block;
      width: 0;
      height: 0;
      -webkit-animation: arrow 1.5s infinite;
              animation: arrow 1.5s infinite;
      margin-top: 15px;
      margin-left: -15px;
      border-top: 15px solid rgba(255, 255, 255, .5);
      border-left: 15px solid transparent;
      border-right: 15px solid transparent; }
@media (min-width: 768px) {
        #intro .opening h3::after {
          margin-left: -20px;
          border-top: 20px solid rgba(255, 255, 255, .5);
          border-left: 20px solid transparent;
          border-right: 20px solid transparent; } }
@media (min-width: 1200px) {
        #intro .opening h3::after {
          margin-left: -40px;
          border-top: 40px solid rgba(255, 255, 255, .5);
          border-left: 40px solid transparent;
          border-right: 40px solid transparent; } }